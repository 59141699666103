var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useEffect, useRef } from 'react';
var EmailContainer = function (props) {
    var ref = useRef(null);
    var handleDivClick = function (event) {
        var selection = window.getSelection();
        var range = document.createRange();
        range.selectNodeContents(event.currentTarget);
        selection === null || selection === void 0 ? void 0 : selection.removeAllRanges();
        selection === null || selection === void 0 ? void 0 : selection.addRange(range);
    };
    useEffect(function () {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.click();
    });
    return (_jsx("div", __assign({ ref: ref, style: { minWidth: '15rem', maxWidth: '30rem' }, onClick: handleDivClick }, { children: props.emailContent }), void 0));
};
export default EmailContainer;
